/* @font-face {
  font-family: "GmarketSansBold";
  src: url("./fonts/GmarketSansBold.otf") format("truetype");
}

@font-face {
  font-family: "GmarketSansLight";
  src: url("./fonts/GmarketSansLight.otf") format("truetype");
}

@font-face {
  font-family: "GmarketSansMedium";
  src: url("./fonts/GmarketSansMedium.otf") format("truetype");
}

@font-face {
  font-family: "KoPubBatangBold";
  src: url("./fonts/KoPubBatangBold.ttf") format("truetype");
}

@font-face {
  font-family: "KoPubBatangLight";
  src: url("./fonts/KoPubBatangLight.ttf") format("truetype");
}

@font-face {
  font-family: "KoPubBatangMedium";
  src: url("./fonts/KoPubBatangMedium.ttf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Black";
  src: url("./fonts/NotoSansKR-Black.otf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Bold";
  src: url("./fonts/NotoSansKR-Bold.otf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Light";
  src: url("./fonts/NotoSansKR-Light.otf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Medium";
  src: url("./fonts/NotoSansKR-Medium.otf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Regular";
  src: url("./fonts/NotoSansKR-Regular.otf") format("truetype");
}

@font-face {
  font-family: "NotoSansKR-Thin";
  src: url("./fonts/NotoSansKR-Thin.otf") format("truetype");
} */

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

div {
  display: flex;
  flex-direction: column;
}

#map div {
  display: block;
}

#map,
#graph {
  background: linear-gradient(-30deg, #3384ff, #0048b5, #3384ff, #0048b5);
  background-size: 400% 400%;
  animation: gradient 6s running infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
